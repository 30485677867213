import * as React from "react"

import "normalize.css"
import Layout from "../components/layout"
import Seo from "../components/seo"
import clickhereGIF from '../images/clickhere.gif'

const AboutPage = () => (
  <Layout>
    <Seo title="Hej" />
    <section className="main">
        <div className="statement">
          <h1 className="left-aligned main-heading">
            Hej<span className="text-yellow">.</span>
          </h1>
          <div>
          <h3 className="main-text">
            Det är vi som är Nevin, Göteborgs trevligaste konsultbolag.
          </h3>
          <p className="main-text">
            Tveka inte att höra av dig till <a href="https://hihello.me/hi/bjorn-ahlander" target="_blank" rel="noopener noreferrer">Björn</a> eller <a href="https://hihello.me/hi/stefan-svensson" target="_blank" rel="noopener noreferrer">Stefan</a> om du vill komma i kontakt med oss. Kanske kan vi ses över en kopp kaffe? &#9749;
          </p>
          <p className="main-text">
            Vi har också precis lanserat en ny karriärssida där du kan läsa allt om vad det innebär att jobba hos oss och om vilka lediga tjänster vi har just nu.
          </p>
          <p className="main-text">
            Gå gärna in och kika på <a href="https://jobb.nevin.se">jobb.nevin.se</a>!
          </p>
          <p className="main-text">
          <img class="super-gif" src={clickhereGIF} alt="Klicka här för karriärsidan!" />
          </p>
          <p className="main-text">
            Du kan också följa oss på Instagram @<a href="https://www.instagram.com/nevintech/">nevintech</a>.
          </p>
          </div>
        </div>
      </section>
  </Layout>
)

export default AboutPage
